<template>
  <div>
    <b-row class="title">
      <p class="p4">
        <img src="../../../../../assets/images/MDI-information-outline.svg" />
        Importante: É necessário ter pelo menos 01 local de atendimento ativo.
      </p>
    </b-row>

    <div v-show="!this.loading">
      <AccordionList
        titleKeyName="clinicName"
        :items="this.tableItems"
        :itemsFields="this.tableFields"
        :iconName="this.iconName"
        :getCustomTable="this.getCustomTable"
        :onEdite="this.onEditeAcordionList"
      />
    </div>
    <div v-show="this.loading">
      <b-skeleton-table
        :rows="3"
        :columns="2"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import AccordionList from "../../../../../components/accordion-list/AccordionList.vue";
import { getAttendencePlacesNotificacoes } from "../../../../../services/attendence-place/attendence-place-service";
import RegisteredAddressesTable from "../../visualization/registeredAddresses/RegisteredAddressesTable.vue";
import { createToast } from "../../../../../components/toast/toast";

Vue.component("occupation-area-table-new", () =>
  import("../registeredAddresses/RegisteredAddressesTable.vue")
);

function validPublish(item) {
  return item == true;
}

export default {
  props: [],
  name: "registered-addresses",
  components: {
    AccordionList,
  },
  mounted() {
    const crm = this.$store.getters.getUserName;
    this.getOccupationAreas(crm);
  },
  data() {
    return {
      tableFields: [],
      tableItems: [],
      medicalAreaOptions: [],
      loading: true,
      iconName: [],
    };
  },
  methods: {
    async getOccupationAreas(crm) {
      try {
        this.loading = true;
        const occupation = await getAttendencePlacesNotificacoes(crm);
        this.loading = false;

        this.tableItems = occupation.map((me) => {
          me.aborted = false;
          me.address.publishMedicalGuide =
            me.address.publishMedicalGuide.toLowerCase() == "sim";
          return me;
        });
      } catch (error) {
        this.loading = false;
        console.error("getOccupationAreas", error);
      }
    },
    onEditePublishMedicalGuideHandler(item) {
      item.aborted = false;
      const publishMapArray = this.tableItems.map(
        (e) => e.address.publishMedicalGuide
      );
      const validFindItemPublished = publishMapArray.some(validPublish);

      if (validFindItemPublished == false) {
        createToast(
          "Informação",
          "É necessário ter pelo menos 01 local de atendimento ativo.",
          "info"
        );
        item.publishMedicalGuide = true;
        item.aborted = true;
      }
    },
    getCustomTable(item) {
      return (
        <RegisteredAddressesTable
          publishEvent={this.onEditePublishMedicalGuideHandler}
          address={item.address}
        />
      );
    },
    onEditeAcordionList(item) {
      console.log("On click do editar do acordion list -> ", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  padding-left: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
</style>
