<template>
  <div>
    <b-table
      class="occupation spaced-text"
      :items="itens"
      :fields="tableFields"
      stacked
    >
      <template v-slot:head(address)="">ENDEREÇO </template>
      <template v-slot:head(publishMedicalGuide)="">
        DIVULGAR NO GUIA MÉDICO?
      </template>

      <template v-slot:cell(address)="row">
        {{ row.item.address }}
      </template>

      <template v-slot:cell(publishMedicalGuide)="row">
        <b-form-checkbox
          switch
          v-model="row.item.publishMedicalGuide"
          @change="onEditeHandler(row.item)"
        >
          {{ row.item.publishMedicalGuide ? "Sim" : "Não" }}
        </b-form-checkbox>
      </template>
    </b-table>
  </div>
</template>

<script>
import { updateAddressPublicizeOption } from "../../../../../services/addresses/addressesService";
import TypeOcurrences from "../../../../../static-data/TypeOcurrences";
import {
  addOccurrenceStorage,
  createNewOcurrence,
} from "../../../../../utils/update-occurrences/occurrences-utils";

export default {
  props: ["address", "publishEvent"],
  components: {},
  mounted() {
    this.address = this.$props.address;
    this.address.phones.forEach((value, index) => {
      this.address["phone" + index] = value == "" ? "/" : value;
      this.tableFields.splice(1, 0, {
        key: "phone" + index,
        label: "TELEFONE",
        tdClass: "text-right",
        thStyle: {
          display: "none",
        },
      });
    });
    this.itens.push(this.address);
  },
  data() {
    return {
      tableFields: [
        {
          key: "address",
          label: "ENDEREÇO",
          tdClass: "text-right",
          thStyle: {
            display: "none",
          },
        },
        {
          key: "publishMedicalGuide",
          label: "DIVULGAR NO GUIA MÉDICO?",
          tdClass: "text-right",
          thStyle: {
            display: "none",
          },
        },
      ],
      itens: [],
    };
  },
  methods: {
    onEditeHandler(item) {
      if (this.$store.getters.isAdmin) {
        return;
      }
      if (this.$props.publishEvent) this.$props.publishEvent(item);

      if (item.aborted == false) {
        var request = {
          crm: this.$store.getters.getUserName,
          type: item.type,
          operation: item.publishMedicalGuide ? "INS" : "DEL",
        };
        updateAddressPublicizeOption(request);

        const publishStart = item.publishMedicalGuide ? false : true;
        const newOcurrence = createNewOcurrence(
          TypeOcurrences.ADDRESS,
          item.addressId,
          item.publishMedicalGuide,
          `Alteração do Endereço - ${item.address}`,
          publishStart
        );
        addOccurrenceStorage(newOcurrence);
      }
    },
  },
};
</script>

<style lang="scss">
.occupation.table td {
  border: none;
  padding: 0;
}
.occupation.table tr {
  padding: 1.5rem 3rem;
  border-top: 1px solid var(--light-0);
}

.occupation.table.b-table.b-table-stacked {
  & :first-child {
    border: none;
    border-color: transparent;
  }
}
.custom-control-label {
  margin-top: 0px;
}
</style>
